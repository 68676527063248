import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Content from './Content'
import ShareLinks from './ShareLinks'
import { Carousel } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

const BlogPostTemplate = ({
  content,
  contentComponent,
  slug,
  tags,
  description,
  gallery,
  date,
  readtime,
  thumbnail,
  title,
  url,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <>
    <div className="blog-image-container">
      <div className="blog-image-inner-container">
        <Img className="blog-image" fluid={thumbnail.childImageSharp.fluid} alt=""  />
        <div style={{ position: 'absolute', top: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0, 0.42)'}}></div>
      </div>
      <div className="container content">
        <div className="columns blog-columns">
          <div className="post-title column is-7 is-offset-2" style={{ paddingBottom: '9rem'}}>
            <h1 style={{color: 'white', marginBottom: 0}} className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <p><small className="accent-color">Posted: {date}<span className="muted">&nbsp;&nbsp;|&nbsp;&nbsp;</span>{readtime}</small><br />
            {tags && tags.length ? (
              <div className="tags">
                {tags.map(tag => (
                  <span className="tag is-rounded is-dark" key={tag + `tag`}>
                    <Link style={{textTransform: 'uppercase', fontWeight: 600, color: 'white'}}to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                  </span>
                ))}
                </div>
            ) : null}
                </p>
            <div class="dropdown is-hoverable" style={{marginBottom: '0'}}>
              <div class="dropdown-trigger">
                <button class="button is-primary is-rounded" aria-haspopup="true" aria-controls="dropdown-menu4">
                  <span>Share</span>
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                </button>
              </div>
              <div class="dropdown-menu" id="dropdown-menu4" role="menu">
                <div class="dropdown-content">
                  <ShareLinks slug={slug} />
                </div>
              </div>
            </div>
            <Link style={{ marginLeft: '1rem'}} className="button is-rounded" to="/latest-updates">All Posts</Link>
          </div>
        </div>
      </div>
    </div>
    <section className="section section--gradient">
      {helmet || ''}
      <Helmet>
        <meta property="og:url"                content={url+slug} />
        <meta property="og:type"               content="article" />
        <meta property="og:title"              content={title} />
        <meta property="og:description"        content={description} />
        <meta property="og:image"              content={url+thumbnail.childImageSharp.fluid.src} />
      </Helmet>
      <div className="container content">
        <div className="columns">
          <div className="column is-7 is-offset-2">
            {/* <hr/> */}
            <PostContent content={content} />
            { gallery !== null ? (
              <Carousel>
              {gallery.map((item) => (
                <Carousel.Item key={item.file.id}>
                  <Img fluid={item.file.childImageSharp.fluid} alt="" />
                  <Carousel.Caption><p></p></Carousel.Caption>
                </Carousel.Item> )
              )}
              </Carousel>
            ) : (<></>)}
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.instanceOf(Helmet),
}

export default BlogPostTemplate
